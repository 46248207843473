import React from "react";
import styled from "@emotion/styled";
import Tree from "@ecologi/assets/icons/Impact/tree.svg";
import Carbon from "@ecologi/assets/icons/Impact/carbon.svg";
import ClimateSolutions from "@ecologi/assets/icons/Impact/climate_solutions.svg";
import Workforce from "@ecologi/assets/icons/Impact/workforce.svg";
import Footprint from "@ecologi/assets/icons/Impact/footprint_filled.svg";
import Measure from "@ecologi/assets/icons/Impact/measure.svg";
import Integrate from "@ecologi/assets/icons/Impact/integrate.svg";
import Marketplace from "@ecologi/assets/icons/Impact/marketplace.svg";
import CarbonSml from "@ecologi/assets/icons/Impact/icon_co2_small.svg";
import TreeSml from "@ecologi/assets/icons/Impact/icon_trees_small.svg";
import WorkforceCircle from "@ecologi/assets/icons/Impact/workforce-circle.svg";
import MeasureCircle from "@ecologi/assets/icons/Impact/measure-circle.svg";
import MarketplaceCircle from "@ecologi/assets/icons/Impact/marketplace-circle.svg";
import IntegrateCircle from "@ecologi/assets/icons/Impact/integrate-circle.svg";
import Compas from "@ecologi/assets/icons/Impact/compas.svg";
import Earth from "@ecologi/assets/icons/Impact/earth.svg";
import Map from "@ecologi/assets/icons/Impact/map.svg";
import Pencil from "@ecologi/assets/icons/Impact/pencil.svg";
import Clap from "@ecologi/assets/icons/Impact/clap.svg";
import World from "@ecologi/assets/icons/Impact/world.svg";
import IntegrationCog from "@ecologi/assets/icons/Impact/integration-cog.svg";
import ZeroChart from "@ecologi/assets/icons/Impact/zero-chart.svg";

export enum ImpactIcons {
  TREE = "tree",
  CARBON = "carbon",
  CLIMATESOLUTIONS = "climate solutions",
  WORKFORCE = "workforce",
  FOOTPRINT = "footprint",
  MEASURE = "measure",
  MARKETPLACE = "marketplace",
  INTEGRATE = "integrate",
  WORKFORCE_CIRCLE = "workforce-circle",
  MEASURE_CIRCLE = "measure-circle",
  MARKETPLACE_CIRCLE = "marketplace-circle",
  INTEGRATE_CIRCLE = "integrate-circle",
  COMPAS = "compas",
  EARTH = "earth",
  MAP = "map",
  PENCIL = "pencil",
  CLAP = "clap",
  WORLD = "world",
  INTEGRATION_COG = "integration-cog",
  ZERO_CHART = "zero-chart",
}

export type ImpactIconProps = {
  icon: ImpactIcons;
  isSmall?: boolean;
};

const sizeIcon = (Icon: React.FunctionComponent, isSmall = false) => styled(
  Icon
)`
  height: ${isSmall ? "56px" : "80px"};
  width: ${isSmall ? "56px" : "80px"};
`;

const iconMapper = {
  [ImpactIcons.TREE]: sizeIcon(Tree),
  [ImpactIcons.CARBON]: sizeIcon(Carbon),
  [ImpactIcons.CLIMATESOLUTIONS]: sizeIcon(ClimateSolutions),
  [ImpactIcons.WORKFORCE]: sizeIcon(Workforce),
  [ImpactIcons.FOOTPRINT]: sizeIcon(Footprint),
  [ImpactIcons.MEASURE]: sizeIcon(Measure),
  [ImpactIcons.MARKETPLACE]: sizeIcon(Marketplace),
  [ImpactIcons.INTEGRATE]: sizeIcon(Integrate),
  [ImpactIcons.WORKFORCE_CIRCLE]: sizeIcon(WorkforceCircle),
  [ImpactIcons.MEASURE_CIRCLE]: sizeIcon(MeasureCircle),
  [ImpactIcons.MARKETPLACE_CIRCLE]: sizeIcon(MarketplaceCircle),
  [ImpactIcons.INTEGRATE_CIRCLE]: sizeIcon(IntegrateCircle),
  [ImpactIcons.COMPAS]: sizeIcon(Compas),
  [ImpactIcons.EARTH]: sizeIcon(Earth),
  [ImpactIcons.MAP]: sizeIcon(Map),
  [ImpactIcons.PENCIL]: sizeIcon(Pencil),
  [ImpactIcons.CLAP]: sizeIcon(Clap),
  [ImpactIcons.WORLD]: sizeIcon(World),
  [ImpactIcons.INTEGRATION_COG]: sizeIcon(IntegrationCog),
  [ImpactIcons.ZERO_CHART]: sizeIcon(ZeroChart),
};

const iconMapperSmall = {
  [ImpactIcons.TREE]: sizeIcon(TreeSml, true),
  [ImpactIcons.CARBON]: sizeIcon(CarbonSml, true),
};

export const ImpactIcon = ({
  icon = ImpactIcons.TREE,
  isSmall,
  ...props
}: ImpactIconProps) => {
  const Icon = isSmall ? iconMapperSmall[icon] : iconMapper[icon];
  if (!Icon) return null;

  return <Icon {...props} />;
};
